<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        <span @click="$router.go(-1)"
          >{{$isExamSearch()?'成绩查询':'成绩管理'}}</span
        >
        / 考生成绩
      </template>
      <template #input>
        <a-input placeholder="请输入关键字" v-model="queryParams.name" />
        <a-select
          placeholder="考试状态"
          v-model="queryParams.examStatus"
          allowClear
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="1">未考试/已发卷</a-select-option>
          <a-select-option :value="2">考试中</a-select-option>
          <a-select-option :value="3">已通过</a-select-option>
          <a-select-option :value="4">未通过</a-select-option>
          <a-select-option :value="5">成绩待发布</a-select-option>
        </a-select>
        <a-button
          type="primary"
          class="btn"
          :loading="loading"
          @click="onSearch"
          >搜索</a-button
        >

        <a-button
          v-if="!$isExamSearch()"
          type="primary"
          class="btn"
          :loading="loadList"
          @click="PublishResults()"
          v-hasPermi="['exam:score:batch']"
          >批量发布</a-button
        >
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <h4 class="title_big">
        <span style="margin-right: 10px">{{ exam.name }}</span>
        <span>场次：<span v-html="exam.time"></span></span>
      </h4>
      <a-table
        class="table-template"
        :row-selection="{
          onChange: onSelect,
          selectedRowKeys: selectedRowKeys,
          getCheckboxProps: (record) => ({
            props: {
              disabled: !(scorePublish == 0 && record.examStatusNum == 2),
            },
          }),
        }"
        :rowKey="(item) => item.examineeId"
        :columns="columns"
        :loading="loading"
        :data-source="tableData"
        :scroll="{ x: 1200 }"
        @change="onPage"
        :pagination="{
          total: total,
          current: queryParams.current,
          defaultPageSize: queryParams.size,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (queryParams.current - 1) * queryParams.size + i + 1 }}
          </div>
        </template>
        <!-- 考试状态 -->
        <template slot="examStatus" slot-scope="text">
          <span
            class="state_all"
            :class="
              text == '考试中'
                ? 'state_color_yellow'
                : text == '已通过'
                ? 'state_color_green'
                : text == '未通过'
                ? 'state_color_red'
                : ''
            "
            >{{ text }}</span
          >
        </template>
        <template slot="replaStatus" slot-scope="text">
          <span v-if="text" class="state_all">{{ text }}</span>
          <span v-else>-</span>
        </template>
        <template slot="operation" slot-scope="text">
          <div class="btn_router_link">
            <a to @click="onExamPicture(text)">考试照片</a>
            <span>|</span>
            <a @click="onSeePaper(text)">查看试卷</a>
            <span>|</span>

            <a @click="getHistory(text)">历史成绩</a>

            <a v-if="scorePublish == 0 && text.examStatusNum == 2 && !$isExamSearch()" @click="singleResults(text.examineeId)"> | 发布成绩</a>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 历史成绩 -->
    <a-modal
      v-model="achievementsShow"
      align="center"
      title="历史成绩"
      :footer="null"
    >
      <a-table
        class="table-template"
        style="margin: 0"
        :columns="columns1"
        :data-source="achievementsList"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ i + 1 }}
          </div>
        </template>
        <template slot="examDate" slot-scope="item, row">
          <div style="text-align: center">
            {{ row.examDate }}
            <br />
            {{ row.timeDate }}
          </div>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
const columns2 = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "身份证号",
    align: "center",
    dataIndex: "idcard",
  },
  {
    title: "进入方式",
    align: "center",
    dataIndex: "entryMode",
  },
  {
    title: "换场状态",
    align: "center",
    scopedSlots: { customRender: "replaStatus" },
    dataIndex: "replaStatus",
  },
  {
    title: "考试状态",
    align: "center",
    dataIndex: "examStatus",
    scopedSlots: { customRender: "examStatus" },
  },
  {
    title: "交卷时间",
    align: "center",
    dataIndex: "submitPaperTime",
  },
  {
    title: "客观成绩",
    align: "center",
    dataIndex: "examscore",
  },
  // {
  //   title: "考试成绩",
  //   align:'center',
  //   dataIndex: "examscore"
  // },
  {
    title: "总成绩",
    align: "center",
    dataIndex: "totalscore",
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: "200px",
    scopedSlots: { customRender: "operation" },
  },
];
const columns1 = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "考试场次日期",
    align: "center",
    dataIndex: "examDate",
    scopedSlots: { customRender: "examDate" },
  },
  {
    title: "总成绩",
    align: "center",
    dataIndex: "totalScore",
  },
  {
    title: "交卷时间",
    align: "center",
    dataIndex: "submitPaperTime",
  },
];
import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columns: [], // 表头
      columns2,
      achievementsList: [], //历史成绩
      columns1,
      achievementsShow: false, // 历史成绩
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量

      selectedRowKeys:[], // 考生id集合
      loadList:false, // 发布成绩防抖

      scorePublish:0,

      queryParams: {
        signupId: 0,
        timeId: 0,
        current: 1, // 页码
        size: 10, // 页数
        examStatus: undefined,
        name: "",
      },

      exam: {
        // 考试名称和场次
        name: "",
        time: "",
      },
    };
  },
  // 事件处理器
  methods: {
    // 选择考生
    onSelect(e,v){
      this.selectedRowKeys = e
    },
    // 分页
    onPage(e) {
      this.queryParams.current = e.current;
      this.queryParams.size = e.pageSize;
      this.getManageList();
    },
    // 考试照片
    onExamPicture(e) {
      this.$router.push({
        path: "/admin/Examination/ExamPicture",
        query: { examineeId: e.examineeId },
      });
    },
    // 查看试卷
    onSeePaper(e) {
      if (!e.userpaperid) {
        this.$message.warning("该考生暂无试卷记录");
        return;
      }
      this.$router.push({
        path: "/admin/Examination/ViewTestPaper",
        query: { userpaperid: e.userpaperid },
      });
    },
    // 高级检索
    onSearch() {
      this.queryParams.current = 1;
      this.getManageList();
    },
    // 获取历史成绩
    getHistory(e) {
      this.achievementsShow = true;
      this.$ajax({
        url:
          "/hxclass-management/exam/examinee/study/record?userId=" +
          e.userId +
          "&courseId=" +
          e.courseId,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.achievementsList = res.data;
        }
      });
    },
    // 查询列表
    getManageList() {
      this.loading = true;
      let obj = JSON.parse(JSON.stringify(this.queryParams))
      if(obj.examStatus == 5) {
        obj.examStatus = 2;
        obj.scorePublishFlag = 1;
      }else if(obj.examStatus == 2) {
        obj.scorePublishFlag = 0;
      }
      this.$ajax({
        method: "get",
        url: "/hxclass-management/mark/achievem/examinee/list",
        params: obj,
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          // 阻止表头重复赋值
          let columns = [];
          columns = JSON.stringify(this.columns2);
          this.columns = JSON.parse(columns);

          if (res.data.records.length) {
            // 重组新的列表表头字段
            let scoreArry = JSON.parse(res.data.records[0].scoreRule);
            scoreArry.forEach((element, index) => {
              let obj = {
                title: element.name,
                align: "center",
                dataIndex: "score" + (index + 1),
              };
              this.columns.splice(this.columns.length - 2, 0, obj);
            });

            // 给数组每个对象追加几个属性
            res.data.records.forEach((element) => {
              JSON.parse(element.scoreRule).forEach((subItem, i) => {
                element["score" + (i + 1)] = subItem.score;
              });
            });
          }

          this.tableData = res.data.records;
          this.total = res.data.total;
        } else {
          this.tableData = [];
          this.total = 0;
          this.$message.error(res.message);
        }
      });
    },

    // 批量发布成绩
    PublishResults() {
      if(!this.selectedRowKeys.length){
        this.$message.error('请至少勾选一名考生');
        return
      }

      if(this.loadList == true){
        return
      }

      let _this = this
      _this.$confirm({
        title: '确认将选择的考生批量发布成绩吗?',
        okText: '确认',
        onOk() {
          _this.loadList = true
          _this.$ajax({
            url: "/hxclass-management/exam/examinee/score/release/" + _this.selectedRowKeys,
            method: "get",
          }).then((res) => {
            _this.loadList = false
            if (res.code == 200 && res.success) {
              _this.$message.success('发布成功');
              _this.selectedRowKeys = []
              _this.queryParams.current = 1;
              _this.getManageList();
            }
          })
        },
        onCancel() {},
      });
    },

    // 单个发布成绩
    singleResults(id) {
      if(this.loadList == true){
        return
      }
      let ids = []
      ids.push(id)
      let _this = this
      _this.$confirm({
        title: '确认将该考生的成绩发布吗?',
        okText: '确认',
        onOk() {
          _this.loadList = true
          _this.$ajax({
            url: "/hxclass-management/exam/examinee/score/release/" + ids,
            method: "get",
          }).then((res) => {
            _this.loadList = false
            if (res.code == 200 && res.success) {
              _this.$message.success('发布成功');
              _this.selectedRowKeys = []
              _this.queryParams.current = 1;
              _this.getManageList();
            }
          })
        },
        onCancel() {},
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.queryParams.signupId = this.$route.query.signupId;
    this.queryParams.timeId = this.$route.query.timeId;
    this.scorePublish = this.$route.query.scorePublish;

    this.exam.name = this.$route.query.examName; // 获取考试名称
    this.exam.time = this.$route.query.time; // 获取考试场次
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.getManageList();
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
.title_big {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 600;
  color: #000;
}
.table-template {
  margin-top: 0;
}
/deep/ .ant-modal-footer {
  text-align: center;
  padding-bottom: 30px;
}
</style>
