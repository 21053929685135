import { render, staticRenderFns } from "./ExamineeScore.vue?vue&type=template&id=1353bb60&scoped=true&"
import script from "./ExamineeScore.vue?vue&type=script&lang=js&"
export * from "./ExamineeScore.vue?vue&type=script&lang=js&"
import style0 from "./ExamineeScore.vue?vue&type=style&index=0&id=1353bb60&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1353bb60",
  null
  
)

export default component.exports